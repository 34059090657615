import { Button, CircularProgress, styled, SxProps } from "@mui/material";
import React from "react"
import { vars, ceraProRegular } from "../../../Theme";
import { useSelector } from "react-redux";
import { IAppReduxStore } from "../../../redux/store";

/** Styled for the UI specifications. */
const SubmitButton = styled(Button)(() => ({
    '&:disabled': {
        backgroundColor: vars.inactiveGreen,
    },
    '&.MuiButton-root': {
        textTransform: 'unset',
        color: vars.white,
        fontSize: 18,
        height: 48,
        ...ceraProRegular
    }
}));

/** A styled button component. */
export default (props: IButtonProps) => {

    const isLoading = useSelector(
      (store: IAppReduxStore) => store.pwService.loading
    );

    const {disabled, label, sx, onClick} = props;

    return (
      <SubmitButton
        variant="contained"
        disabled={disabled}
        onClick={onClick}
        children={isLoading ? <CircularProgress /> : label}
        fullWidth
        sx={sx}
      />
    );
}

export interface IButtonProps {
    onClick: () => void;
    disabled?: boolean;
    label: string;
    sx?: SxProps;
  }
  