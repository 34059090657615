import { createTheme } from '@mui/material/styles';

const fontOpenSans = { fontFamily: '"OpenSans", sans-serif' };
const fontCeraProBold = { fontFamily: '"CeraPro Bold", sans-serif' };
const fontCeraProRegular = { fontFamily: '"CeraPro Regular", sans-serif' };

/** Font family for main application text. */
export const openSans = fontOpenSans;

/** Font family for headers. */
export const ceraProBold = fontCeraProBold;

/** Font family for context menus and buttons. */
export const ceraProRegular = fontCeraProRegular;

/** Application Theme. */
export default createTheme({
    palette: {
        primary: {
            main: '#44AC34',
        },
        secondary: {
            main: '#edf2ff',
        },
    },
    typography: {
        h1: {
            ...fontCeraProBold,
        },
        h2: {
            ...fontCeraProBold,
        },
        h3: {
            ...fontCeraProBold,
        },
        h4: {
            ...fontCeraProBold,
        },
        h5: {
            ...fontCeraProBold,
        },
        h6: {
            ...fontCeraProBold,
        },
        body1: {
            ...fontOpenSans,
        },
        body2: {
            ...fontOpenSans,
        },
    },
});

/** Color variables. */
export const vars = {
    black: '#000' as const,
    font: '#434343' as const,
    concrete: '#898B8E' as const,
    midGray: '#C2C2C2' as const,
    lightGray: '#DEDEDE' as const,
    fillGray: '#F2F2F2' as const,
    bgGray: '#FBFBFB' as const,
    white: '#FFF' as const,

    errorRed: '#B00020' as const,
    errorBgRed: '#F8E6E9' as const,

    warningYellow: '#E6B012' as const,
    warningBgYellow: '#FDF7E8' as const,
    warningInactiveBgYellow: '#FEFCF6' as const,

    logoBlue: '#0F3949' as const,

    brandGreen: '#44AC34' as const,
    brandOrange: '#FF8400' as const,
    brandPink: '#F8374A' as const,
    brandLightBlue: '#88D2E7' as const,
    brandBlue: '#003C4D' as const,
    brandYellow: '#FFCE00' as const,

    hoverGreen: '#2E8B20' as const,
    mainGreen: '#44AC34' as const,
    inactiveGreen: '#8FCD85' as const,
    bgGreen: '#EDF7EB' as const,
};