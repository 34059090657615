import React from "react";

import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import { Box, Stack, SxProps, Typography } from "@mui/material";
import { ceraProBold, vars } from "../../../Theme";
import { useSelector } from "react-redux";
import { IAppReduxStore } from "../../../redux/store";

import en from "./i18n/Status_en.json";
import de from "./i18n/Status_de.json";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";

i18n.addResourceBundle("en", "status", en);
i18n.addResourceBundle("de", "status", de);

/** Component to show a global success or error status. */
export default () => {
  // Configure component translation.
  const { t } = useTranslation("status");

  /** Access relevant state variables from the redux store. */
  const message = useSelector((store: IAppReduxStore) => store.status.message);
  const type = useSelector((store: IAppReduxStore) => store.status.type);

  /** Validators for the components UI */
  const isEmpty = message?.length > 0;
  const isError = !!type?.match(/error/);

  const styles: SxProps = {
    color: isError ? vars.errorRed : vars.brandGreen,
    backgroundColor: isError ? vars.errorBgRed : vars.bgGreen,
    borderLeft: "4px solid",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    px: 1,
    width: "100%",
    ...ceraProBold,
  };

  return (
    <Box sx={{ height: 80 }}>
      {isEmpty && (
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          sx={{ ...styles }}
        >
          <Typography
            variant="body2"
            sx={{ my: 1, ...ceraProBold }}
            children={t(message)}
          />
          <Box flexGrow={4} />
          {isError ? <Error /> : <CheckCircle />}
        </Stack>
      )}
    </Box>
  );
};
